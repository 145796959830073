@import url('https://fonts.googleapis.com/css?family=Mukta'); 

body {
    margin: 0;
    padding: 0;
    font-family: 'Mukta', sans-serif;
}

ol, ul{
    padding-left: 1em;
}


/* Layout */

.ant-space.justify-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

/* End Layout */

/* Alert */

.swal-text {
    text-align: center;
}

.alert-justify .swal-text{
    text-align: justify;
    margin: 0px 1em;
    padding: 1em;
    background: #fffbe6;
}

/* End Alert */


/* Buttons */

.app-btn {
    width: auto;
    height: auto;
    padding: .5em 1em;
    transition: .3s;
    border: 1px solid  #d9d9d9;
    border-radius: 4px;
    /* font-weight: bold; */

    /* white-space: pre-wrap; */
    /* overflow-wrap: anywhere; */
}

.app-btn.block{
    width: 100%;
}

.app-btn.long{
    min-width: 100px;
}

.app-btn.lg{
    padding: .75em 2em;
    font-size: 1.1em;
}

.app-btn.xl{
    padding: .75em 2em;
    font-size: 1.3em;
    font-weight: bold;
}

.app-btn.bold{
    font-weight: bold;
}


.app-btn.text{
    border: 0px solid #fff;
    background: transparent;
}

.app-btn.primary:not([disabled]){
    background-color: #FFE600;
    border: 1px solid  #FFE600;
    color: #072A6F;
}

.app-btn.primary:not([disabled]):hover{
    color: #FFE600;
    background-color: #072A6F;
    border-color: #072A6F;
}

.app-btn.primary:not([disabled]):focus{
    /* color: #072A6F; */
    /* background-color: #072A6F; */
    border-color: #072A6F;
}

.app-btn.primary[disabled=""]{
    color: rgba(0, 0, 0, 0.25) !important;
    /* background-color: #072A6F; */
    border-color: rgba(0, 0, 0, 0.25) !important;
}

.app-btn.primary[disabled]:focus{
    color: rgba(0, 0, 0, 0.25) !important;
    /* background-color: #072A6F; */
    border-color: #072A6F;
}


.app-btn.secondary:not([disabled]){
    background-color: #072A6F;
    border: 1px solid  #072A6F;
    color: #FFE600;
}

.app-btn.secondary:not([disabled]):hover{
    color: #072A6F;
    background-color: #FFE600;
    border-color: #FFE600;
}

.app-btn.secondary:not([disabled]):focus{
    /* color: #FFE600; */
    /* background-color: #072A6F; */
    border-color: #072A6F;
}


.app-btn.danger:not([disabled]){
    background-color: #EB5757;
    border: 1px solid  #EB5757;
    color: #ffffff;
}


.app-btn.danger:not([disabled]):hover, .app-btn.danger:not([disabled]):focus{
    background-color: transparent;
    color: #EB5757;
}

.app-btn-dropdown{
    background-color: #072A6F;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    color: #FFE600;
    border-radius: 32px;
}


/* Buttons */

/* Card */

.app-card {
    border-radius: 4px;
}

.ant-card.app-card.paper{
    border: 1px solid #c7c7c7;
}

.app-card .ant-card-body .app-card-info-user,
.app-card .ant-card-body {
    height: 100%;
}

.app-card.paper{
    border: 1px solid 
}

.app-card.paper .app-card-info-user.paper{
    background: #f0f0f0;
    border: 0px solid #ffffff;
}

.app-card.paper .app-card-info-user.paper .ant-card-body{
    padding: 15px 20px;
}

.app-card-info-user {
    border-radius: 16px !important;
}

/* End Card */


/* Tabs */

.app-tab-card .ant-card, .app-tab-card .ant-tabs-nav::before, .app-tab-card .ant-tabs-nav .ant-tabs-tab{
    border-color: #d9d9d9;
}

.app-tab-card .ant-tabs-nav{
    margin-bottom: 0px;
}

.app-tab-card .ant-card{
    border-top-width: 0px;
}

.app-tab-card .ant-card.paper{
    border-top-width: 1px;
}

.ant-tabs.app-tabs.nodisable .ant-tabs-tab.ant-tabs-tab-disabled {
    cursor: default;
    color: #595959;
}

/* End Tabs */


/* App.js */
.app-layout {
    background-color: #F5F6FA;
    min-height: 100vh;
}

.app-footer{
    background: #191d3d;
    /* box-shadow: 0px -5px 10px rgba(0, 0, 0, .1); */
    /* color: #F5F6FA; */
    color: #A2A5BB;
    /* font-weight: ; */
}

.container-footer {
    padding: 1em 2em;
}

/* End App.js */


/* Login */
.card-site-login{
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    width: 100%;
    /* height: 450px; */

    /* Secondary */

    border: 4px solid #032464;
    background-color: #191d3d;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
}

.card-site-login .ant-card-body{
    width: 100%;
}

.card-site-login .ant-card-body input{
    width: 100%;
}

.title-container {
    display: flex;
    flex-direction: column;
    left: 20%;
    top: 15%;
    background-color: #191d3d;
    border: 0px;
}

.title-login {
    font-size: 4.5em;
    line-height: 100%;
    color: #fff;
    right: 50px;
}

@media screen and (max-width: 1366px) {
    .title-login {
        font-size: 3.5em;
    }
}

@media screen and (max-width: 992px) {
    .title-login {
        font-size: 2.75em;
    }
}

@media screen and (max-width: 768px) {
    .title-login {
        font-size: 2.25em;
    }
}


.label-login{
    color: #ffffff;
    font-size: 24px;
}

.site-layout-login{
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #191d3d;
    justify-content: center;
}

.login-form-button {
    width: 200px;
    height: 46px;
    background-color: #FFE600 ;
    border-color: #FFE600 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

.form-group-login,
.form-group{
    border-radius: 8px ;
    /* width: 400px ; */
    height: 50px;
    align-self: center;
}

.form-group-modal,
.form-group{
    border-radius: 16px ;
    /* width: 350px ; */
    margin-top: 2em;
}

.label-modal {
    width: 437px;
    height: 70px;
    left: 48px;
    top: 108px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

.label-modal-login{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

.modal-login {
    padding: 24px;
    word-wrap: break-word;
    position: relative ;
    width: 433px ;
    height: 266px ;
}

.modal-login,
.ant-modal-content {
    border-radius: 8px ;
}

.input.ant-input.form-group-login{
    margin-top: 1em;
}

.btn-modal-login{
    width: 123px ;
    height: 32px ;
    color: #ffffff ;
    background: #27AE60 ;
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.13) ;
    border-radius: 16px ;
}

.steps-content {
    min-height: 200px;
}

.steps-action {
    text-align: right;
    margin-top: 20px;
}

.form-modal .ant-modal-content {
    border-radius: 8px;
}

.form-modal-middle .ant-modal-content {
    width: 600px;
    max-width: 100%;
    margin: auto;
    border-radius: 10px;
}

.form-modal .ant-modal-body, .form-modal-middle .ant-modal-body {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 35px;
    padding-left: 35px;
}

.input-modal {
    border-radius: 4px;
    /* background: #f2f2f2; */
}

.input-modal .ant-input {
    /* background: #f2f2f2; */
}

.form-button {
    width: 150px;
    height: 46px;
    background-color: #FFE600 ;
    border-color: #FFE600 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

.form-button-white {
    width: 150px;
    height: 46px;
    background-color: #FFF ;
    border-color: #d9d9d9 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

/* End of login  */


/* Data Diri */

.ant-form-item.no-handler .ant-input-number-handler-wrap {
    display: none;
}

/* End of Data DIRI */


/* Data Akademik */
.ant-form-item.no-error .ant-form-item-explain {
    display: none;
}

.ant-form-item.no-error {
    margin-bottom: 0px;
}

.data-akademik-modal .ant-form-item .ant-input-number-handler-wrap{
    display: none;
}
/* End Data Akademik */


/* Forgot Password */
.site-layout-forgotpass{
    height: 100%;
    background-color: #f5f6fa;
    margin: 20px;
}

.forgotpass-form-button {
    margin-top: 10px;
    width: 200px;
    height: 46px;
    background-color: #FFE600 ;
    border-color: #FFE600 ;
    color: #191d3d ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}

.form-group-forgotpass {
    border-radius: 24px ;
    width: 300px ;
    height: 40px;
    padding-left: 1em;
    align-self: left;
    font-weight: bold;
    font-size: 16px;
    color: #072A6F;
}
/* End of Forgot Password */


/* HeaderLayout.js */

.ant-layout-content{
    padding: 0px 2em;
}

.site-layout-content{
    margin: 2em auto 0px;
    width: 80%;
}

.header-loyola.ant-layout-header{
    background-color: #f5f6fa;
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 10px;
    height: 80px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.header-loyola .wrapper {
    display: flex;
    justify-content: space-between;
}

.header-loyola .name {
    color: #FFF;
    margin-right: 10px;
}

.header-loyola .title {
    font-weight: bold;
    font-size: 32px;
    color: #072A6F;
    text-align: left;
    margin-left: 20px;
}

.header-loyola .menu {
    font-weight: bold;
    font-size: 20px;
}

.area-header-title {
    text-align: left;
}

.area-header-burger {
    text-align: center;
}

.area-header-menu {
    text-align: center;
}

.site-page-header .ant-page-header-heading-title{
    color: #072A6F;
    font-size: 2em;
}

/* End HeaderLayuot.js */

/* Bip Tester.js */
.card-biptester {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.title-biptester {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: right;
    margin-left: 10px;

    color: #000000;
}
/* End Bip Tester.js */

/* Verifikator.js */

.input-search {
    height: 40px;
    border-radius: 32px;
    background: #FFFFFF;
}

.card-verifikator {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.title-verifikator {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: right;

    color: #000000;
}

.text-heading-verifikator {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 80px;
}

/* End Verifikator.js */

/* Interviewer.js */

.input-search {
    height: 40px;
    border-radius: 32px;
    background: #FFFFFF;
}

.card-interviewer {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.title-interviewer {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: right;

    color: #000000;
}

/* End Interviewer.js */

/* Dashboard */
.site-layout-dashboard{
    height: 100%;
    background-color: #f5f6fa;

}

.card-prestasi {
    border-radius: 15px;
    height: 399px;
    overflow: auto hidden;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65))), url('../../assets/prestasi.png') no-repeat;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/prestasi.png') no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
}

.card-tes {
    height: 399px;
    border-radius: 15px;
    overflow: auto hidden;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(59%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.65))), url('../../assets/tes.png') no-repeat;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 59%, rgba(0, 0, 0, 0.65) 100%), url('../../assets/tes.png') no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
}

.card-content {
    position: absolute;
    bottom: 20px;
}

.card-grid {
    width: 100%;
    margin-right: 20px;
    border-radius: 15px;
    border: 1px solid #f0f0f0;
}

.text-heading {
    margin-bottom: 10px !important;
    color: #072A6F;
}

.text-title {
    color: #000;
    font-size: 32px;
    font-weight: bold;
}

.text-subtitle {
    color: #000;
    /* font-size: 24px; */
    font-size: 1.2em;
    /* font-weight: bold; */
    padding-left: 5;
}

.text-subtitle-daftarulang {
    color: #000;
    /* font-size: 24px; */
    font-size: 1.2em;
    font-weight: bold;
    padding-left: 5;
}

.container-signature{
    align-items: center;
    justify-content: center;
}

.title-signature {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color:#000;
}

.signature-button {
    background-color: #191d3d ;
    color: #FFE600 ;
    font-weight: bold;
    font-size: 16px;
}

.verifikasi-wait{
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 24;
    color: '#072A6F'
  }
.verifikasi-succes{
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 24;
    color: '#219653'
}
.verifikasi-fail{
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 24;
    color: '#EB5757'
}
/* End Dashboard */


/* Jalur Pendaftaran */

.card-step {
    min-height: 450px;
    border-radius: 15px;
    overflow: hidden auto;
}

.avatar-step {
    background: #072A6F;
    float: left;
    margin-top: 6px;
    font-weight: 600;
}

.text-desc {
    color: #072A6F;
    font-size: 1.2em;
}




@media screen and (max-width: 576px) {

    .site-layout-forgotpass{
        margin: 0px;
    }

    .card-site-login{
        display: flex;
        flex-direction: column;
        left: 0;
        /* Secondary */
    
        border: 4px solid #032464;
        background-color: #191d3d;
        box-sizing: border-box;
        border-radius: 16px;
        align-items: center;
    }

}


.subtitle{
    font-size: 1.2em;
    margin-bottom: 1em;
    font-weight: bold;
}

/* table */

.app-table{
    margin-bottom: 2em;
}

.app-table thead td{
    background: #f5f6fa;
}

.app-table td{
    padding: .5em 1em;
}

.app-table tbody tr:hover td{
    background: #f5f6fa;
}

.app-table tbody:before {
    /* This doesn't work because of border-collapse */
    line-height:1em;
    content:"_";
    color:white;
    display:block;
}

.app-table td .ant-input-number{
    width: auto;
}

.app-table.stripped tbody tr:hover td{
    background: #ffffff;
}

.app-table.stripped table tr:nth-child(even) td{
    background: #f5f6fa;
}

.req-star{
    display: inline-block;
    margin: auto 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
}

/* Card verifikasi data */
.form-button-tes {
    width: 200px;
    height: 46px;
    background-color: #191d3d ;
    /* border-color: #FFE600 ; */
    color: #FFE600 ;
    border-radius: 8px ;
    font-weight: bold;
    font-size: 16px;
}
/* Card verifikasi data end*/


/* Upload Component */

.app-upload .ant-upload-select .ant-upload-select-text, .ant-upload{
    width: 100%;
}

.app-upload.ant-upload .ant-upload-list-item-card-actions .anticon-delete{
    color: #ff4d4f
}

.app-upload.split-upload{
    display: flex;
    justify-content: left;
    align-content: center;
    /* justify-content: ; */
}

.app-upload.split-upload .ant-upload-select-text{
    width: auto;
    /* margin-right: 1em; */
    width: 40%;
}

.app-upload.split-upload .ant-upload-list-text {
    width: 60%;
    /* flex: 1; */
}

.app-upload.split-upload .ant-upload-list-text > div {height: 100%;}
.app-upload.split-upload .ant-upload-list-text > div > span {height: 100%; display: block;}

.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item {
    height: 100%;
    margin-top: 0px;
    /* padding: 5px 0px; */
}

.app-upload .ant-upload-list-text .ant-upload-list-item:hover .ant-upload-list-item-info{
    background: #ffffff;
}



.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item .ant-upload-list-item-info > span{
    /* background: #fff; */
    display: flex;
    align-items: center;
}

.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item-card-actions {
    /* margin-right: 5px; */
}

.app-upload.split-upload .ant-upload-list-text .ant-upload-list-item-name {
    padding-left: 10px;
    /* font-weight: bold; */
    /* height: 100%; */
    /* display: block; */
}



.app-upload.split-upload .ant-upload-text-icon{
    display: none;
}

/* End Upload Component */


/* BipTester.js */
.save-button, .save-button:hover {
    background-color:#FFE600;
    border-radius: 8px;
    color: #072A6F;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.cancel-button, .cancel-button:hover {
    background-color:#EB5757;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 6px 24px;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.text-title-bip {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: #000000;
}

.card-bip {
    width: 100%;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
}

.back-button-bip {
    position: absolute;
    left: -16.67%;
    color: #072A6F!important;
}

.item-penilaian-bip {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #072A6F;
}

.bip-divider {
    border: 2px solid #E0E0E0;
    margin: 12px 0;
}

.custom-dropdown-bip {
    background-color: #072A6F;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #FFE600;
    border-radius: 32px;
    padding-right: 60px;
    padding-left: 24px;
}

/* End BipTester.js*/

/* Dropdown Component custom style */
.custom-select .ant-select-selector, .custom-select .ant-select-arrow, .custom-dropdown {
    color: #FFE600 !important;
}
.custom-select .ant-select-selection-placeholder {
    opacity: 0.8!important;
}
.custom-select .ant-select-selector {
    border-radius: 32px!important;
}
.custom-select .ant-select-selector, .custom-dropdown {
    background-color: #072A6F!important;
}

.custom-btn-daful {
    font-size: 0.75em;
    font-weight: bolder;
}

.footerDivider {
    border: 1px solid #A2A5BB;
}


/* finance */

.text-header-finance {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.5;
    color: #222222;
}

.divider-finance {
    border-bottom: 4px solid #CBE723;
    border-radius: 5px;
}

.btn-finance {
    height: 110px ;
    border-radius: 15px;
    border: none;
    outline: none;
    margin-bottom: 25px;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    white-space: pre-wrap;
}

.btn-finance:hover, .btn-finance:focus {
    background: #191D3D;
    color: #FFEF5F;
}

.btn-finance:hover > span, .btn-finance:focus > span {
    color: #FFEF5F;
    white-space: pre-wrap;
}

.btn-finance > span {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
    color: #F5F5F5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-flat {
    background: #259136;
}

.btn-plus {
    background: #FFEF5F;
}

.btn-plus > span {
    color: #191D3D ;
}

.btn-nego {
    background: #C22121;
}

.grandient-image::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 45.24%);
  }

.card-finance {
    border-radius: 15px;
    min-height: 300px;
    max-height: 300px;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    overflow: hidden;
}

.card-finance > .ant-card-body {
    padding: 0;
}

.container-text {
    margin-top: 230px;
    margin-left: 10px;
    position: absolute;
}

.image-style {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 20rem;
}

.text-card-bottom {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-card-top {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.icon-cancel {
    content: '';
    cursor: pointer;
    margin: auto;
    width: 100%;
    height: 100%;
    display: block;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMDAwMzMgMC42NjY2NTZDNC4zOTE5OSAwLjY2NjY1NiAwLjY2Njk5MiA0LjM5MTY2IDAuNjY2OTkyIDguOTk5OTlDMC42NjY5OTIgMTMuNjA4MyA0LjM5MTk5IDE3LjMzMzMgOS4wMDAzMyAxNy4zMzMzQzEzLjYwODcgMTcuMzMzMyAxNy4zMzM3IDEzLjYwODMgMTcuMzMzNyA4Ljk5OTk5QzE3LjMzMzcgNC4zOTE2NiAxMy42MDg3IDAuNjY2NjU2IDkuMDAwMzMgMC42NjY2NTZaTTEzLjE2NyAxMS45OTE3TDExLjk5MiAxMy4xNjY3TDkuMDAwMzMgMTAuMTc1TDYuMDA4NjYgMTMuMTY2N0w0LjgzMzY2IDExLjk5MTdMNy44MjUzMyA4Ljk5OTk5TDQuODMzNjYgNi4wMDgzMkw2LjAwODY2IDQuODMzMzJMOS4wMDAzMyA3LjgyNDk5TDExLjk5MiA0LjgzMzMyTDEzLjE2NyA2LjAwODMyTDEwLjE3NTMgOC45OTk5OUwxMy4xNjcgMTEuOTkxN1oiIGZpbGw9IiNFMDRDNEMiLz4KPC9zdmc+Cg==') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.modal-style > .ant-modal-content > .ant-modal-close > .ant-modal-close-x {
    width: 50px;
    height: 40px;
}

.modal-style > .ant-modal-content > .ant-modal-body {
    padding: 24px 32px;
}

.head-modal {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #222222;
}

.head-finance {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    color: #222222;
}

.body-text-modal {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    color: #222222;
    white-space: pre-line
}

.btn-text-back > span {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.5;
    color: #191D3D;
}

.btn-text-back:hover, .btn-text-back:focus {
    background: transparent;
}

.text-detail-lov {
    float: right;
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    text-decoration-line: underline;
    color: #848484;
}
.text-detail {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    text-decoration-line: underline;
    color: #848484;
    cursor: pointer;
}

.ol-finance {
    padding: 0;
    margin: 0;
}

.li-finance {
    padding-left: 16px;
}

.li-finance:before {
    content: "•"; /* Insert content that looks like bullets */
    padding-right: 8px;
    color: #222222;
    font-size: 22px;
}

.text-diskon {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #EB5757;
}

.btn-upload {
    border-radius: 10px;
    border: none;
    outline: none;
    background: #E0E0E0;
}

.btn-upload:hover, .btn-upload:focus {
    background: #c7c7c7;
}

.btn-upload > span {
    font-family: 'Mukta', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.5;
    color: #191D3D;
}

.text-Kekl {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #191D3D;
}

.inpt-seni {
    outline: 0;
    border: none;
    background: #E0E0E0;
    border-radius: 10px;
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.5;
    color: rgba(25, 29, 61, 0.5);
}

.inpt-seni:placeholder-shown {
    color: rgba(25, 29, 61, 0.5);
}
.inpt-seni:focus, .inpt-seni-focused {
    border-color: transparent !important;
    box-shadow: none
}

.slct-seni {
    width: 100%;
}

.slct-seni > .ant-select-selector {
    outline: 0;
    border: none !important;
    background: #E0E0E0 !important;
    border-radius: 10px !important;
    height: 30px !important;
}

.slct-seni > .ant-select-selector > .ant-select-selection-item {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(25, 29, 61, 0.7);
}

.slct-seni > .ant-select-selector > .ant-select-selection-placeholder {
    font-family: 'Mukta';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(25, 29, 61, 0.5);
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: transparent !important;
    box-shadow: none
}

/* end finance */


/* ujian akademik */

.card-ujian-akademik {
    background: #072A6E;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    color: #fff;
    justify-content: center;
    text-align: center;
    padding: 20;
    min-height: 180;
    width: 100%;
}

.card-ujian-akademik-selected {
    background: #FFE500;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    justify-content: center;
    color: #072A6E;
    text-align: center;
    padding: 20;
    border-color: '#FFE500' ;
    min-height: 180;
    width: 100%;
}
.textSoal{
    font-size: 24;
}
.textJawaban{
    font-size: 24;
}
.cardJawaban{
    min-height: 180;
    width: '100%';
}
.paddingSoal{
    padding: 1.5em 3em;
}

@media screen and (max-width: 768px) {
    .textSoal {
        font-size: 12;
    }
    .textJawaban{
        font-size: 12;
    }
    .paddingSoal{
        padding: 10px;
    }
    .cardJawaban{
        min-height: 140;
        width: '100%';
    }
    .card-ujian-akademik-selected {
        min-height: 140;
    }
    .card-ujian-akademik {
        min-height: 140;
    }
}

/* end ujian akadmik */